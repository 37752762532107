import { Component, OnInit } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "../../../shared/shared.module";
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CountUpModule } from 'ngx-countup';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-index-classic-saas',
  templateUrl: './index-classic-saas.component.html',
  styleUrls: ['./index-classic-saas.component.css'],
  standalone:true,
  imports: [SharedModule, FeatherModule, ScrollToModule, CountUpModule, CarouselModule],
})

/***
 * Classic Saas Component
 */
export class IndexClassicSaasComponent implements OnInit {

  // Set Topbar Option
  /* option peges */
  navClass = 'nav-dark';
  Menuoption = 'job';
  Settingicon = false;

  
  constructor(private modalService: NgbModal) { 
  }
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 2,
  };

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-airplay h2 text-primary",
      title: "Easy To Use",
      description: "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper."
    },
    {
      icon: "uil uil-calendar-alt h2 text-primary",
      title: "Daily Reports",
      description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    },
    {
      icon: "uil uil-clock h2 text-primary",
      title: "Real Time Zone",
      description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    }
  ];

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Free",
      price: 0,
      list: ["Full Access", "Source Files"],
      btn: "Buy Now"
    },
    {
      warning: "Best",
      title: "Starter",
      price: 39,
      list: ["Full Access", "Source Files", "Free Appointments"],
      btn: "Get Started"
    },
    {
      title: "Professional",
      price: 59,
      list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security"],
      btn: "Try It Now"
    },
    {
      title: "Ultimate",
      price: 79,
      list: ["Full Access", "Enhanced Security", "Source Files", "1 Domain Free", "Free Installment"],
      btn: "Started Now"
    }
  ];

  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
