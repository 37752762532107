<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/software/bg.png') center center;">
  <div class="bg-overlay-custom"></div>
  <div class="container">
    <div class="row align-items-center position-relative mt-5" style="z-index: 1;">
      <div class="col-lg-6 col-md-12">
        <div class="title-heading mt-4 text-lg-left">
          <h1 class="heading mb-3 title-dark text-white">SOLUCIONES A MEDIDA</h1>
          <p class="para-desc text-white-50">Desarrollamos soluciones tecnológicas para mejorar el desempeño y los resultados de tu empresa. Sobre todo, creamos
          soluciones para ti.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-file-download"></i> Download Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-12 mt-4 pt-2">
        <div class="position-relative">
          <div class="software_hero">
            <img src="assets/images/software/software.png" class="img-fluid d-block" alt="">
          </div>
          <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon border-0">
              <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Section Start -->


  <article class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4"> NUESTROS SERVICIOS</h4>
          <p class="text-muted para-desc mx-auto mb-0">Ofrecemos una gama de servicios que se adaptan para mejorar la calidad de tu Empresa</p>
        </div>
      </div>
    </div>
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center mt-5"></app-services>
    <div class="row justify-content-center mt-5 pt-4">
      <div class="col-lg-10 col-md-12">
        <img src="assets/images/software/mobile-hori.png" class="img-fluid d-block mx-auto" alt="">
      </div>
    </div>
  </article>

  <article class="container mt-100 mt-60">
    <div class="card rounded bg-light shadow border-0">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-sm-7">
            <div class="text-sm-left">
              <h5 class="mb-0">Start building beautiful block-based websites.</h5>
            </div>
          </div>
          <div class="col-sm-5 mt-4 mt-sm-0">
            <div class="text-sm-end">
              <a href="javascript:void(0)" class="btn btn-outline-primary"><i class="uil uil-file-bookmark-alt"></i>
                Start Now </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>

  <section class="section pt-md-4 pt-5">
  
    <article class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6">
          <img src="assets/images/software/mobile01.png" class="img-fluid" alt="">
        </div>
  
        <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">CARACTERÍSTICAS QUE FORTALECEN NUSTROS SERVICIOS</h4>
            <!-- <p class="text-muted">Ofrecemos una gama de servicios que se adaptan para mejorar la calidad de tu Empresa.
            </p> -->
            <ul class="list-unstyled mb-0 text-muted">
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>Responsive</b> diseño que se adapta a dispositivos móviles, tablets y pantallas de todo tamaño.</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>Velocidad</b> nos aseguramos que nuestros sitios tengan la mejor velocidad posible, para su satisfacción.</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>Diseño</b> ofrecemos diseños 100% personalizables y sin limites.</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>SEO</b> mejoramos la visibilidad de su sitio en los resultados de los diferentes buscadores.</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>Administrable</b> contamos con un panel de facil uso para administrar su sitio.</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span><b>Seguridad</b> brindamos sólido apoyo y protegemos su sitio web de cualquier ataque.</li>
            </ul>
          </div>
        </div>
  
      </div>
    </article>
  <!--end container-->

  
  <!--end container-->

  <!-- Price Start -->
  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Chose your perfect Plan</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div> -->
  <!--end container-->
  <!-- Price End -->

  <!-- Start Testimonial -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Testimonial -->
</section>
<!--end section-->
<!-- section End -->

<!--  -->
<!-- <section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">See everything about your employee at one place.</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>

          <div class="mt-4 pt-2">
            <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary">Get Started <span
                class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--  -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->