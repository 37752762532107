import { Component, OnInit } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-index-saas',
  templateUrl: './index-saas.component.html',
  styleUrls: ['./index-saas.component.css'],
  standalone:true,
  imports:[ScrollToModule, FeatherModule, SharedModule]
})

/***
 * Sass Components
 */
export class IndexSaasComponent implements OnInit {

   // Set Topbar Option
  /* option peges */
  navClass = 'nav-light';
  Menuoption = 'job';
  Settingicon = false;


  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Thomas Israel",
      designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-airplay h2 text-primary",
      title: "Proceso de Creación",
      description: "Contamos con un proceso para crear productos simples, pero con un proceso completo y escalable."
    },
    {
      icon: "uil uil-calendar-alt h2 text-primary",
      title: "Nuevas Tecnologías",
      description: "Aprendemos y nos gusta revolucionar aplicando nuevas tecnologías a proyectos reales."
    },
    {
      icon: "uil uil-clock h2 text-primary",
      title: "Reporte y Data",
      description: "Todos nuestros proyectos intentan colectar toda la data posible para su posterior uso en un CRM / CDP."
    }
  ];

}
