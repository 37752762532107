

<section class="bg-home d-flex align-items-center bg-light" id="home" style="height: auto;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9 text-center mt-0 mt-md-5 pt-0 pt-md-5 pb-md-5">
                <div class="title-heading margin-top-100 margin-botton-100">
                    <h1 class="heading mb-4">Más de <span class="text-primary">10 Años </span> Acreditan Nuestro Trabajo</h1>
                   <!--  <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p> -->
                    <!-- <ul class="mt-4 list-unstyled mb-0 align-items-center">
                        <li class="list-inline-item"><a routerLink="/page-contact-one" class="btn btn-primary me-2"><i class="uil uil-envelope"></i> Request a Demo</a></li>
                        <li class="list-inline-item text-muted me-2 h6">Or</li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary fw-bold"> Try it for Free <i class="uil uil-angle-right-b align-middle"></i></a></li>
                    </ul> -->
                </div>
                <!-- <div class="home-dashboard">
                    <img src="assets/images/social/hero.png" alt="" class="img-fluid mover">
                </div> -->
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="section p-0 pb-2 margin-top-100">
    <div class="container">
        <div class="row" id="counter">
            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="assets/images/solunes/1icon-min.svg" class="avatar avatar-small" alt="">
                    <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="2" [options]="option"></span>M
                    </h2>
                    <h6 class="counter-head text-muted">Usuarios</h6>
                </div>
        
            </div>
        
            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="assets/images/solunes/2icon-min.svg" class="avatar avatar-small" alt="">
                    <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="100" [options]="option"></span>M$</h2>
                    <h6 class="counter-head text-muted">Movimiento</h6>
                </div>
        
            </div>
        
            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="assets/images/solunes/3icon-min.svg" class="avatar avatar-small" alt="">
                    <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="2000" [options]="option"></span>%
                    </h2>
                    <h6 class="counter-head text-muted">crecimiento generado</h6>
                </div>
        
            </div>
        
            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="assets/images/solunes/4icon-min.svg" class="avatar avatar-small" alt="">
                    <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="120" [options]="option"></span></h2>
                    <h6 class="counter-head text-muted">proyectos</h6>
                </div>
            </div>
        </div>
    </div>
    
</section>
<!--Shape End-->

<!-- Work Start -->
<section class="section">
    <div class="container-fluid">

        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div id="grid" class="row container-grid projects-wrapper">
                    @for(item of filterredImages;track $index){
                    <div class="col-xl-4 col-lg-6 col-sm-12 mt-4 pt-2 picture-item" data-groups='["branding"]' data-aos="fade-up"
     data-aos-duration="3000" data-aos-once="true">
                        <div class="card border-0 work-container work-classic">
                            <div class="card-body p-0">
                                <a [href]="item['url']" target="_blank" ><img src="{{item.image}}"
                                        class="img-fluid rounded work-image" alt=""></a>
                                <div class="content pt-3">
                                    <h5 class="mb-0"><a [href]="item['url']" target="_blank" class="text-dark title">{{item.title}}</a></h5>
                                    <!-- <h6 class="text-muted tag mb-0">{{item.type}}</h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->