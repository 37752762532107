<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home"
  style="background: url('assets/images/travel/bg.jpg') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mt-4">
          <h1 class="display-4 fw-bold text-white title-dark mb-3">Booking tour <br> made easy</h1>
          <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-lg btn-light">
              <i-feather name="arrow-down" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="card shadow rounded border-0 ms-lg-5">
          <div class="card-body">
            <h5 class="card-title">You can start search here</h5>

            <form class="login-form">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Destination</label>
                    <div class="form-icon position-relative">
                      <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="Where" name="s" required>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                      <label class="form-label"> Check in : </label>
                      <input name="date" type="text" class="form-control start" placeholder="Select date :" [(ngModel)]="checkin" mwlFlatpickr [convertModelValue]="true">
                  </div>
              </div><!--end col-->

              <div class="col-md-6">
                  <div class="mb-3">
                      <label class="form-label"> Check out : </label>
                      <input name="date" type="text" class="form-control end" placeholder="Select date :" [(ngModel)]="checkout" mwlFlatpickr [convertModelValue]="true">
                  </div>
              </div><!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email</label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">I Accept <a href="javascript:void(0)"
                          class="text-primary">Terms And Condition</a></label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="d-grid">
                    <button class="btn btn-primary">Search Now</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Benefit for Traveller</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-shield-check"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Secure Payment</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-thumbs-up"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Easy Book</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-keyboard-show"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Free Amenities</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-award"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Best Offers</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-bookmark"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Cheap than Other</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-favorite"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Top Rated</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>24/7 Support</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon-color">
            <i class="uil uil-process"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Fast Refund</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript:void(0)" class="btn btn-primary">See more
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-end mb-4 pb-2">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h6 class="text-primary">Find Out Most</h6>
          <h4 class="title mb-4">Popular Destination</h4>
          <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-end">
          <a href="javascript:void(0)" class="text-primary h6">See More
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-4 pt-2">
        <owl-carousel-o id="six-slide" [options]="customOptions" class="owl-stage-outer">
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/dubai.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Dubai</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/thailand.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Thailand</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/india.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">India</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/london.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Londan</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/paris.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Paris</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/singapore.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Singapore</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>
<!--end section-->

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title fw-bold mb-4">Everyone Needs Travel. <br> Want to Break Free for a While</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/travel/1.jpg" class="card-img-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="card-body content">
            <h5><a href="javascript:void(0)" class="card-title title text-dark">Conversations, Feedback, Recognition</a>
            </h5>
            <div class="post-meta d-flex justify-content-between mt-3">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                      class="uil uil-heart me-1"></i>33</a></li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                      class="uil uil-comment me-1"></i>08</a></li>
              </ul>
              <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                  class="uil uil-angle-right-b align-middle"></i></a>
            </div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
            <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Experience Luxury & <br> Find Your Base</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Search Destination <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/travel/3.jpg" class="img-fluid rounded shadow-md" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">100% Money back <br> Guarantee if You Cancel</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
        </div>

        <div class="faq-content mt-4 pt-2">
          <div ngbAccordion activeIds="div ngbAccordionItem-0" closeOthers="true">
            <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
              <h2 ngbAccordionHeader>
                <div class="border-0 bg-light" id="headingtwo">
                  <button ngbAccordionButton class="title mb-0">How does it work ?
                  </button>
                </div>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                <ng-template class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
              <h2 ngbAccordionHeader>
                <div class="border-0 bg-light" id="headingtwo">
                  <button ngbAccordionButton class="title mb-0"> Do I need a designer to use Landrick ? </button>
                </div>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                <ng-template class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
              <h2 ngbAccordionHeader>
                <div class="border-0 bg-light" id="headingtwo">
                  <button ngbAccordionButton class="title mb-0"> What do I need to do to start selling ? </button>
                </div>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                <ng-template class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
              <h2 ngbAccordionHeader>
                <div class="border-0 bg-light" id="headingtwo">
                  <button ngbAccordionButton class="title mb-0"> What happens when I receive an order ? </button>
                </div>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                <ng-template class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</ng-template>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/travel/2.jpg" class="img-fluid rounded shadow-md" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">We have More than 500 <br> Selected Destination</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Search Destination <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Whats Our Clients Said About <span class="text-primary">Landrick</span> Project</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->