<!-- Hero Start -->
<section class="bg-half-260 bg-light2 d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <h4 class="heading mt-2 mb-4">Activaciones digitales</h4>
                    <p class="para-desc mx-auto text-muted mb-0">Te brindamos soluciones para generar activaciones digitales que funcionan igual que una activación BTL tradicional, pero a gran escala y menor costo automatizando procesos.</p>

                    <!-- <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-pills btn-primary m-1">Contactar</a>
                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-primary m-1">Learn More</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-11">
                <div class="features-absolute">
                    <div id="grid" class="row">
                        <ngx-masonry>
                            @for(item of seoAgency;track $index){
                            <div ngxMasonryItem class="spacing picture-item">
                                <img src="{{item.images}}" class="img-fluid shadow seo-hero-widget rounded-md" alt="">
                            </div>
                        }
                            <!--end col-->
                        </ngx-masonry>
                    </div>
                    <!--end row-->
                </div>
                <!--end div-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mt-3 mb-4">¿Qué problemas resolvemos?</h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Dificultad para identificar la promoción más efectiva.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Dificultades para tomar decisiones empresariales basadas en los resultados de las campañas.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Integración entre diferentes bases de datos.</li>
                    </ul>
                </div>
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mt-3 mb-4">¿Cómo vamos a resolver los problemas?</h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Integración entre diferentes sitemas</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Registro de información de clientes en servidores</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Base de datos en tiempo real.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"></span>Seguridad.</li>
                    </ul>
                </div>
            </div>
        </div>
        <app-services [servicesData]="servicesData" service_class="features feature-primary text-center mt-5"></app-services>
        <div class="col-12 mt-5 text-center">
            <a href="javascript:void(0)" class="btn btn-pills btn-primary">See More</a>
        </div>
    </div> -->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/solunes/ac-1.png" class="img-fluid" alt="">
                </div>
            </div>

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">¿Qué problemas resolvemos?</h4>
                    <!-- <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p> -->
                    <ul class="list-unstyled text-muted">
                        <!-- <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Datos estadisticos tomar acción.</li> -->
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Dificultad para identificar la promoción más efectiva.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Dificultades para tomar decisiones empresariales basadas en los resultados de las campañas.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Integración entre diferentes bases de datos.</li>
                    </ul>

                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/solunes/lapto-web.png" class="img-fluid" alt="">
                </div>
            </div>

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">¿Cómo vamos a resolver los problemas?</h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Integración entre diferentes sitemas </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registro de información de clientes en S/Splus.com.bo. </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Generación de KPI (Key performance indicators). </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Base de datos en tiempo real. </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Seguridad. </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="section bg-light">
    <div class="container">
       
        <div class="row justify-content-center">

            <div class="col-md-6 col-12 mt-2 pt-2 ">
                <div class="d-flex">
                    <div class="flex-1">
                       <h4 class=""><b>1. Integración entre diferentes sitemas</b></h4>
                        <ul class="list-unstyled mb-5">
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Base de datos unificada con perspectivas claras en un solo lugar.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Control de duplicidad para IMEIs y números de serie.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Seguridad de datos y respaldo.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Una base de datos consolidada de IMEIs y números de serie para todas las campañas.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 mt-2 pt-2">
                <div class="d-flex">
                    <div class="flex-1">
                       <h4 class="mb-4"><b>2. Registro de información de clientes en S/Splus.com.bo.</b></h4>
                        <ul class="list-unstyled mb-5">
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Diferentes validaciones.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Entidad Reguladora AJ.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Distintos tipos de campañas.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Reglas variadas.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Coordinación con proveedores.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-2 pt-2">
                <div class="d-flex">
                    <div class="flex-1">
                       <h4 class="mb-4"><b>3. Generación de KPI (Key performance indicators).</b></h4>
                        <ul class="list-unstyled mb-5">
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Descarga de informes de información en cualquier momento.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Mapas de ciudades, áreas de ventas y tiendas.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Perfil del comprador.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>ROI (Retorno sobre la inversión).</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Análisis de productos y clientes.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Incentivos de ventas.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Productividad y efectividad de las campañas.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-2 pt-2">
                <div class="d-flex">
                    <div class="flex-1">
                       <h4 class="mb-4"><b>4. Base de datos en tiempo real.</b></h4>
                        <ul class="list-unstyled mb-5">
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Toma de decisiones en el momento.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Pronóstico del comportamiento y la actividad del consumidor y del mercado.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Control y eficiencia de las campañas.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Seguimiento de datos generados diariamente.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Asegurar estratégicamente la rentabilidad de las inversiones.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-2 pt-2">
                <div class="d-flex">
                    <div class="flex-1">
                       <h4 class="mb-4 "><b>5. Seguridad.</b></h4>
                        <ul class="list-unstyled mb-5">
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Aceptación del Checklist de Seguridad del Sistema</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Aceptación de la Guía de Protección de Datos de Samsung.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Implementación de Firewall y Zabbix.</li>
                            <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Medidas de la guía PCI, así como otras medidas de seguridad adicionales.</li>
                        </ul>
                    </div>
                </div>
            </div>



        </div>


    </div>
</section>

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <ngx-slick-carousel class="carousel" [config]="config">
                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/01.jpg" class="img-fluid" alt="">
                            
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/02.jpg" class="img-fluid" alt="">

                            
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/03.jpg" class="img-fluid" alt="">


                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/04.jpg" class="img-fluid" alt="">

                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/05.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/06.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/07.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/08.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/09.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/10.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/11.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" ngxSlickItem>
                    <div
                        class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                        <div class="card-img position-relative">
                            <img src="assets/images/blog/travel/12.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>


        </div>
    </div>
</div> -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->