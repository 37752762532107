import { Component } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-corporate-services',
  templateUrl: './corporate-services.component.html',
  styleUrls: ['./corporate-services.component.css'],
  standalone:true,
  imports:[
    FeatherModule, ScrollToModule
  ]
})
export class CorporateServicesComponent {
// Set Topbar
   /* option peges */
  navClass = 'nav-light';
  Menuoption = 'job';
  Settingicon = false
}
