<!-- Hero Start -->
<section class="bg-home d-flex align-items-center"
  style="background: url('assets/images/saas/home-shape.png') center center; height: auto;" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading mb-3">Centraliza todo de manera simple </h1>
          <p class="para-desc mx-auto text-muted">Contamos con un CRM que tiene el objetivo de ser simple, fácil de usar y práctico para el cliente final. Nos integramos con cualquier plataforma para la obtención y centralización de data.</p>
          <div class="mt-4 pt-2">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary">Contactar <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>

        <div class="home-dashboard">
          <img src="assets/images/solunes/bolivar-home.webp" alt="img" class="img-fluid">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section bg-light mt-0 mt-md-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Expertos en generación de data</h4>
          <p class="text-muted para-desc mx-auto mb-0">En <span class="text-primary fw-bold">Solunes Digital</span> tuvimos varios casos de éxito en la generación de un CRM centralizado para clubes y empresas con comunidades amplias. </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <app-services [servicesData]="servicesData" service_class="features feature-primary"></app-services>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img src="assets/images/solunes/dash-pege.png" class="img-fluid shadow rounded" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">¿Qué nos diferencia?</h4>
          <p class="text-muted">Nuestro CRM no está listo para usar, sino que debe ser personalizado y adaptado a las necesidades del cliente final. Al fin podrás tener un:</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>CRM que realmente genere resultados</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Enfoque en el negocio a medida</li>
          </ul>
          <a href="https://wa.link/dk5urn" target="_blank" class="mt-3 h6 text-primary">Contactar <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">Plataforma de autogestión para clientes</h4>
          <p class="text-muted">Contamos con una alta experiencia en el diseño de plataformas de autogestión para clientes finales. Compleméntalo:</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Con una plataforma de pagos para cliente final</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Con una plataforma de autogestión a medida</li>
          </ul>
          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/solunes/iPad-Mini-socio.png" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Whats Our Clients Said About <span class="text-primary">Landrick</span> Project</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
    </div>
  </div> -->

  <div class="container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Expertos en clientes finales</h4>
          <p class="text-muted para-desc mx-auto mb-0">Contamos con una amplia experiencia en sistemas orientados al cliente final. Esta experiencia nos permitió desarrollar plataformas que generen experiencias amigables y simples para el cliente final.</p>

          <div class="mt-4">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary mt-2 me-2">Contactar</a>
            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a> -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end sectipn-->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!-- Client End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->