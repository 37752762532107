<router-outlet></router-outlet>

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>

<div class="btn-what" (click)="numberContact()" placement="top" ngbTooltip="Contactar">
  <svg width="800" height="800" viewBox="0 0 100 100" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><style>.st125{fill-rule:evenodd;clip-rule:evenodd;fill:#f1f1f1}</style><g id="Layer_2"><path class="st125" d="M82.773 17.048c-38.434-37.321-98.728 8.848-72.606 55.645L3.622 96.589l24.455-6.412c46.787 24.737 91.578-35.212 54.696-73.129z"/><path d="M50.143 88.745c-7.593.027-14.974-2.253-21.34-6.295l-14.796 3.879 3.95-14.421c-4.438-6.577-6.941-14.291-6.907-22.275-.07-34.549 42.425-52.164 66.746-27.615 24.516 24.34 6.906 66.803-27.653 66.727z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#66e066"/><path class="st125" d="M71.585 59.476c-1.175-.588-6.953-3.431-8.03-3.823-1.077-.392-1.861-.588-2.644.589-.784 1.176-3.034 3.822-3.72 4.605-.685.785-1.371.883-2.546.295-2.539.339-15.564-10.676-15.988-13.97-.685-1.175-.073-1.812.516-2.398 1.023-1.123 2.318-2.535 2.937-4.018.392-.785.196-1.471-.098-2.059-.294-.588-2.578-6.4-3.623-8.723-.88-1.957-1.807-1.996-2.644-2.031-1.983.031-3.518-.484-5.386 1.443-7.004 6.741-3.792 16.214.685 21.955.587.784 8.13 13.028 20.075 17.738 9.927 3.915 11.947 3.136 14.102 2.94 2.155-.196 6.953-2.842 7.932-5.586.979-2.744.979-5.096.686-5.587-.295-.488-1.079-.782-2.254-1.37z"/></g></svg>
</div>

<!-- <app-switcher></app-switcher> -->

