<!-- Hero Start -->
<section class="vh-85 d-flex align-items-center"
  style="background: url('assets/images/contact-detail.jpg') center center;" id="home">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h1 class="heading">Contáctate con nosotros</h1>
          <p class="text-dark">Comience a trabajar con <span class="text-primary fw-bold">Solunes Digitales </span> que puede brindarle todo lo que necesita para tu negocio.</p>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Correo electrónico</h5>
              <a href="mailto:contact@example.com" class="text-primary">info&#64;solunes.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Teléfono</h5>
              <a href="tel:+152534-468-854" class="text-primary">+591 60569450</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Ubicación</h5>
              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">Ver ubicación</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d478.09211854297195!2d-68.07614486425368!3d-16.53937256734873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f213604f30def%3A0xb88305cbb9e7221a!2sSolunes+Digital!5e0!3m2!1ses!2sbo!4v1533313415980"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
          <ul class="list-unstyled social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="https://www.facebook.com/solunesdigital" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <!-- <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li> -->
            <!-- <li class="list-inline-item ms-1"><a href="https://bo.linkedin.com/company/solunes-digital" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li> -->
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section">

  <div class="container mt-60">

    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Sucursales</h4>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-primary feature-clean">
          <div class="icons text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-4">
            <h5 class="fw-bold">La Paz - Bolivia</h5>
            <p class="text-muted">Av Ballivian y Calle 23, Torre Faith 6F, La Paz, <br> Calacoto, La Paz</p>
            <p class="m-0">Teléfono: <a href="tel:+591 60569450" class="text-primary">+591 60569450</a></p>
            <p class="m-0">Correo electrónico: <a href="mailto:info@solunes.com" class="text-primary">info&#64;solunes.com</a></p>
            <a (click)="mapView(content)" href="javascript:void(0);" class="video-play-icon text-primary">Ver ubicación</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                  height="450" width="750">
                </iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-primary feature-clean">
          <div class="icons text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-4">
            <h5 class="fw-bold">La Paz - Bolivia</h5>
            <p class="text-muted">Av Ballivian y Calle 23, Torre Faith 6F, La Paz, <br> Calacoto, La Paz</p>
            <p class="m-0">Teléfono: <a href="tel:+591 60569450" class="text-primary">+591 60569450</a></p>
            <p class="m-0">Correo electrónico: <a href="mailto:info@solunes.com" class="text-primary">info&#64;solunes.com</a></p>
            <a (click)="mapView(content)" href="javascript:void(0);" class="video-play-icon text-primary">Ver ubicación</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                  height="450" width="750">
                </iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-primary feature-clean">
          <div class="icons text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-4">
            <h5 class="fw-bold">La Paz - Bolivia</h5>
            <p class="text-muted">Av Ballivian y Calle 23, Torre Faith 6F, La Paz, <br> Calacoto, La Paz</p>
            <p class="m-0">Teléfono: <a href="tel:+591 60569450" class="text-primary">+591 60569450</a></p>
            <p class="m-0">Correo electrónico: <a href="mailto:info@solunes.com" class="text-primary">info&#64;solunes.com</a></p>
            <a (click)="mapView(content)" href="javascript:void(0);" class="video-play-icon text-primary">Ver ubicación</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                  height="450" width="750">
                </iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</section>



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->