<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Blog Listing </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li>
          <li class="breadcrumb-item active" aria-current="page">Blog List</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!--Blog Lists Start-->
<section class="section">
  <div class="container">
    <div class="row">
      @for(data of blogListData;track $index){
      <div class="col-lg-6 col-12 mb-4 pb-2">
        <div class="card blog blog-primary rounded border-0 shadow overflow-hidden">
          <div class="row align-items-center g-0">
            <div class="col-md-6">
              <img src="{{data.image}}" class="img-fluid" alt="">
              <div class="overlay"></div>
              <div class="author">
                <small class="user d-block"><i class="uil uil-user"></i> {{data.name}}</small>
                <small class="date"><i class="uil uil-calendar-alt"></i> {{data.date}}</small>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-6">
              <div class="card-body content">
                <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.title}}</a>
                </h5>
                <p class="text-muted mb-0">{{data.content}}</p>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                          class="uil uil-heart me-1"></i>{{data.like}}</a></li>
                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                          class="uil uil-comment me-1"></i>{{data.message}}</a></li>
                  </ul>
                  <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end blog post-->
      </div>
    }
      <!--end col-->
      <!-- PAGINATION START -->
      <div class="col-12">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
          <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
        </ul>
      </div>
      <!--end col-->
      <!-- PAGINATION END -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section -->
<!--Blog Lists End-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->