<div class="row d-flex justify-content-center flex-wrap ">
    @for(data of pricingData;track $index){ 
    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 text-sm-start ng-star-inserted">
        <div class="card pricing pricing-primary business-rate shadow border-0 rounded h-100" [ngClass]="{'bg-light': !data.warning}">
            <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                    class="text-center d-block shadow small h6">{{data.warning}}</span></div>
            <div class="card-body">
                <h6 class="title fw-bold text-uppercase text-primary mb-4">{{data.title}}</h6>
                <div class="d-flex mb-4">
                    <span class="h4 mb-0 mt-2"> {{data.current ?? '$'}}</span>
                    <span class="price h1 mb-0">{{data.price}}</span>
                    <span class="h4 align-self-end mb-1">/{{data.date ?? 'mo'}}</span>
                </div>
                @for(item of data.list;track $index){ 
                <ul class="list-unstyled mb-0 ps-0">
                    <li class="h6 text-muted mb-0 d-flex"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>{{item}}</li>
                </ul>
            }
                <a href="javascript:void(0)" class="btn btn-primary mt-4">{{data.btn}}</a>
            </div>
        </div>
    </div>
}
    <!--end col-->
</div>