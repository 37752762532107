<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Tables </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Tables</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>
            
            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Table Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Table </h5>
                            </div>

                            <div class="p-4">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="border-bottom">#</th>
                                            <th scope="col" class="border-bottom">First</th>
                                            <th scope="col" class="border-bottom">Last</th>
                                            <th scope="col" class="border-bottom">Handle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>&#64;mdo</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td>&#64;fat</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Harry</td>
                                                <td>Potter</td>
                                                <td>&#64;hpt</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Table End -->

                    <!-- Table Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Table Hover </h5>
                            </div>

                            <div class="p-4">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table table-hover mb-0 table-center">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="border-bottom">#</th>
                                            <th scope="col" class="border-bottom">First</th>
                                            <th scope="col" class="border-bottom">Last</th>
                                            <th scope="col" class="border-bottom">Handle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>&#64;mdo</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td>&#64;fat</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Harry</td>
                                                <td>Potter</td>
                                                <td>&#64;hpt</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Table End -->

                    <!-- Table Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Table Dark Hover </h5>
                            </div>

                            <div class="p-4">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table table-dark table-hover mb-0 table-center">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="border-bottom">#</th>
                                            <th scope="col" class="border-bottom">First</th>
                                            <th scope="col" class="border-bottom">Last</th>
                                            <th scope="col" class="border-bottom">Handle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>&#64;mdo</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td>&#64;fat</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Harry</td>
                                                <td>Potter</td>
                                                <td>&#64;hpt</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Table End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
